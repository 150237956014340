import { getInitialDateRange } from '@/utils/dayHandle';
import { getDemoMode } from '@/utils/demoMode';
import { useIntl } from '@umijs/max';
import { Modal, Space } from 'antd';
import type dayjs from 'dayjs';
import type { RangeValue } from 'rc-picker/lib/interface';
import { useEffect, useState } from 'react';
import DateRange from '../DateRange';
import { Description } from './styles';

export type QueryFilterProps = {
  dateRange: RangeValue<dayjs.Dayjs>;
};

const DownloadModal: React.FC<{
  onClose: () => void;
  open: boolean;
  onConfirm: (query: QueryFilterProps) => Promise<void>;
}> = ({ onClose, open, onConfirm }) => {
  const { formatMessage } = useIntl();
  const [loading, setLoading] = useState(false);
  const [queryFilter, setQueryFilter] = useState<QueryFilterProps>(generateDafaultState());

  function generateDafaultState(): QueryFilterProps {
    return { dateRange: getInitialDateRange() };
  }

  const onSubmit = () => {
    setLoading(true);
    onConfirm(queryFilter).finally(() => {
      setLoading(false);
      onClose();
    });
  };

  useEffect(() => {
    if (!open) {
      setQueryFilter(generateDafaultState());
    }
  }, [open]);

  return (
    <Modal
      title={formatMessage({
        id: 'overview.modal.download.title',
        defaultMessage: 'Download CSV File',
      })}
      confirmLoading={loading}
      open={open}
      onOk={onSubmit}
      onCancel={onClose}
      okText={formatMessage({ id: 'common.download', defaultMessage: 'Download' })}
      cancelText={formatMessage({ id: 'common.action.cancel', defaultMessage: 'Cancel' })}
      okButtonProps={{ disabled: getDemoMode() }}
    >
      <Description>
        <Space direction="vertical" size={16}>
          {formatMessage(
            {
              id: 'overview.modal.download.description',
              defaultMessage: `You're about to download a CSV file containing essential data rows, including ads spend and conversions. {component} If you're ready to proceed, click the \"Download\" button below.`,
            },
            {
              component: (
                <DateRange
                  disabled={getDemoMode()}
                  allowClear={false}
                  value={queryFilter.dateRange}
                  onChange={(dateRange) => setQueryFilter((prev) => ({ ...prev, dateRange }))}
                />
              ),
            },
          )}
        </Space>
      </Description>
    </Modal>
  );
};

export default DownloadModal;
