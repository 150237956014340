import { getUserApi, putUserApi } from '@/services/user';
import { history, useModel } from 'umi';

export default () => {
  const { initialState, setInitialState } = useModel('@@initialState');

  const fetchUser = async () => {
    return getUserApi()
      .then(async (user) => {
        if (user) {
          setInitialState({
            ...initialState,
            currentUser: user,
          });
        }
      })
      .catch((err) => console.log(err));
  };

  const updateUser = async (params: API.PutUserPersonalParams | API.PutUserPasswordParams) =>
    putUserApi(params).then(async (user) => {
      if (user) {
        setInitialState({
          ...initialState,
          currentUser: user,
        });
      }
    });

  const loginOnSuccess = () => {
    fetchUser().then(() => {
      history.push('/overview/traffic');
    });
  };

  return {
    fetchUser,
    loginOnSuccess,
    updateUser,
  };
};
