const DISABLED_COLOR = '#99A6BB';
const Neutral_4 = '#f0f0f0';
// only apply to style-component theme
export const advanceTheme = {
  'disabled-color': DISABLED_COLOR,
  'netural-color-4': Neutral_4,
};

const theme = {
  token: {
    fontFamily:
      "'Inter', 'ui-sans-serif', 'system-ui', '-apple-system', 'BlinkMacSystemFont', 'Segoe UI', 'Roboto', 'Helvetica Neue', 'Arial', 'Noto Sans TC', 'sans-serif', 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'",
    fontSize: 14,
    fontSizeHeading1: 38,
    fontSizeHeading2: 30,
    fontSizeHeading3: 24,
    fontSizeHeading4: 20,
    fontSizeHeading5: 16,
    colorTextBase: 'rgba(0, 0, 0, 0.85)',
    colorTextHeading: 'rgba(0, 0, 0, 0.85)',
    colorPrimaryBg: '#E6F4FF',
    colorPrimaryBgHover: '#BAE0FF',
    colorPrimaryBorder: '#91CAFF',
    colorPrimaryBorderHover: '#69B1FF',
    colorPrimaryHover: '#4096FF',
    colorPrimary: '#1677FF',
    colorPrimaryActive: '#0958D9',
    colorPrimaryTextHover: '#4096FF',
    colorPrimaryText: '#1677FF',
    colorPrimaryTextActive: '#0958D9',
    //
    colorSuccessBg: '#F6FFED',
    colorSuccessBgHover: '#D9F7BE',
    colorSuccessBorder: '#B7EB8F',
    colorSuccessBorderHover: '#95DE64',
    colorSuccessHover: '#95DE64',
    colorSuccess: '#52C41A',
    colorSuccessActive: '#389E0D',
    colorSuccessTextHover: '#73D13D',
    colorSuccessText: '#52C41A',
    colorSuccessTextActive: '#389E0D',
    //
    colorWarningBg: '#FFFBE6',
    colorWarningBgHover: '#FFF1B8',
    colorWarningBorder: '#FFE58F',
    colorWarningBorderHover: '#FFD666',
    colorWarningHover: '#FFD666',
    colorWarning: '#FAAD14',
    colorWarningActive: '#D48806',
    colorWarningTextHover: '#FFC53D',
    colorWarningText: '#FAAD14',
    colorWarningTextActive: '#D48806',
    //
    colorErrorBg: '#FFF2F0',
    colorErrorBgHover: '#FFF1F0',
    colorErrorBorder: '#FFCCC7',
    colorErrorBorderHover: '#FFA39E',
    colorErrorHover: '#FF7875',
    colorError: '#FF4D4F',
    colorErrorActive: '#D9363E',
    colorErrorTextHover: '#FF7875',
    colorErrorText: '#FF4D4F',
    colorErrorTextActive: '#D9363E',
    //
    colorInfoBg: '#E6F4FF',
    colorInfoBgHover: '#BAE0FF',
    colorInfoBorder: '#91CAFF',
    colorInfoBorderHover: '#69B1FF',
    colorInfoHover: '#69B1FF',
    colorInfo: '#1677FF',
    colorInfoActive: '#0958D9',
    colorInfoTextHover: '#4096FF',
    colorInfoText: '#1677FF',
    colorInfoTextActive: '#0958D9',
    //
    colorBgContainer: '#FFFFFF',
    colorBgElevated: '#FFFFFF',
    colorBgLayout: 'linear-gradient(#ffffff, #f0f5fc 28%)',
    colorBgSpotlight: 'rgba(0, 0, 0, 0.85)',
    colorBgMask: 'rgba(0, 0, 0, 0.45)',
    //
    colorFill: 'rgba(0, 0, 0, 0.15)',
    colorFillQuaternary: 'rgba(0, 0, 0, 0.02)',
    colorFillSecondary: 'rgba(0, 0, 0, 0.06)',
    colorFillTertiary: 'rgba(0, 0, 0, 0.04)',
    //
    colorBorder: '#D9D9D9',
    colorBorderSecondary: '#F0F0F0',
    //
    colorText: 'rgba(0, 0, 0, 0.88)',
    colorTextSecondary: 'rgba(0, 0, 0, 0.65)',
    colorTextTertiary: 'rgba(0, 0, 0, 0.45)',
    colorTextQuaternary: 'rgba(0, 0, 0, 0.25)',
    seedTokenWhite: '#ffffff',
    //
    fontWeightStrong: 500,
  },
  components: {
    Table: {
      borderRadius: 0,
      borderRadiusLG: 0,
      fontSize: 14,
    },
    Card: {
      borderRadiusLG: 0,
      padding: 16,
    },
    Typography: {
      titleMarginBottom: 0,
    },
    Progress: {
      colorSuccess: '#1677FF',
    },
  },
};

export default theme;
