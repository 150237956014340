import { downloadPerformance } from '@/services/performance';
import { getDateRangeFormat } from '@/utils/formatDate';
import { useIntl } from '@umijs/max';
import { Button, notification } from 'antd';
import type { IconType } from 'antd/es/notification/interface';
import FileSaver from 'file-saver';
import { useState } from 'react';
import type { QueryFilterProps } from './DownloadModal';
import DownloadModal from './DownloadModal';

const DownloadCsv = () => {
  const { formatMessage } = useIntl();
  const [open, setOpen] = useState(false);

  const [api, contextHolder] = notification.useNotification();

  const openNotification = ({
    description,
    title,
    type = 'success',
  }: {
    title: string;
    description: string;
    type?: IconType;
  }) => {
    api[type]({
      message: title,
      description,
      placement: 'topRight',
    });
  };

  const showSuccessMessage = () => {
    openNotification({
      title: formatMessage({
        id: 'overview.popup.download.success.title',
        defaultMessage: 'Download Complete',
      }),
      description: formatMessage({
        id: 'overview.popup.download.success.description',
        defaultMessage:
          'Your download is complete! The CSV file containing essential data rows has been successfully downloaded to your device.',
      }),
    });
  };

  const showFailedMessage = () => {
    openNotification({
      title: formatMessage({
        id: 'overview.popup.download.failed.title',
        defaultMessage: 'Download Failed',
      }),
      description: formatMessage({
        id: 'overview.popup.download.failed.description',
        defaultMessage:
          'here may have been an issue with the download process. Please ensure that you have a stable internet connection and try again.',
      }),
      type: 'error',
    });
  };

  const handleDownload = async (query: QueryFilterProps) => {
    const { thisTermStart, thisTermEnd } = getDateRangeFormat(query);
    return downloadPerformance({
      thisTermStart: thisTermStart as string,
      thisTermEnd: thisTermEnd as string,
    })
      .then((fileData) => {
        FileSaver.saveAs(new Blob([fileData?.data as Blob]), fileData?.fileName);
      })
      .then(() => showSuccessMessage())
      .catch(() => {
        showFailedMessage();
      });
  };

  return (
    <>
      {contextHolder}
      <Button onClick={() => setOpen(true)}>
        {formatMessage({ id: 'common.download.csv', defaultMessage: 'Download CSV' })}
      </Button>
      <DownloadModal open={open} onClose={() => setOpen(false)} onConfirm={handleDownload} />
    </>
  );
};

export default DownloadCsv;
