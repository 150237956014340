import { disabledDate } from '@/utils/dayHandle';
import { useIntl } from '@umijs/max';
import { DatePicker } from 'antd';
import type { Dayjs } from 'dayjs'; // Add this import
import dayjs from 'dayjs';
import type { RangeValue } from 'rc-picker/es/interface';
import React, { useState } from 'react';
const { RangePicker } = DatePicker;

const dateOptionsConfig = [7, 14, 30, 90];

const DateRange: React.FC<{
  onChange: (values: RangeValue<Dayjs>) => void;
  value?: RangeValue<Dayjs>;
  disabled?: boolean;
  allowClear?: boolean;
  maxDate: Dayjs;
  minDate: Dayjs;
}> = ({ value, onChange, disabled, allowClear = true, maxDate, minDate }) => {
  const [dates, setDates] = useState<RangeValue<Dayjs>>(null);
  const { formatMessage } = useIntl();

  const rangePresets: {
    label: string;
    value: [Dayjs, Dayjs]; // Update the type here
  }[] = dateOptionsConfig.map((days) => ({
    label: formatMessage({ id: 'general.daterange.last' }, { days }),
    value: [dayjs().subtract(days, 'day'), dayjs().subtract(1, 'day')],
  }));

  return (
    <RangePicker
      presets={rangePresets}
      allowClear={allowClear}
      value={value}
      disabledDate={(current) => disabledDate(current as Dayjs, dates)}
      onChange={(val) => {
        onChange(val as RangeValue<dayjs.Dayjs>);
      }}
      disabled={disabled}
      onCalendarChange={(val) => {
        setDates(val as RangeValue<dayjs.Dayjs>);
      }}
      maxDate={maxDate}
      minDate={minDate}
    />
  );
};

export default DateRange;
