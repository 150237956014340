import { getRequestOptionDemoMode } from '@/utils/demoMode';
import { request } from 'umi';

export const getProductAllAPI = async (
  params: API.ProductAllGetParameters,
  options?: API.RequestOptions,
) => {
  return request<API.ProductAll>('/product/all', {
    params,
    ...options,
    ...getRequestOptionDemoMode(),
  });
};

export const getProductListAPI = async (
  params: API.ProductListGetParameters,
  options?: API.RequestOptions,
) => {
  return request<API.ProductList>('/product/list', { params, ...options });
};
