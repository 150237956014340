import dayjs from 'dayjs';

import type { Dayjs } from 'dayjs';
import type { RangeValue } from 'rc-picker/es/interface';

import utc from 'dayjs/plugin/utc';
dayjs.extend(utc);

type InternalNamePath = string[];
type NamePath = string | InternalNamePath;

export const utcConvertLocalDate = (date: string) => {
  const stillUtc = dayjs.utc(date).toDate();
  const localDate = dayjs(stillUtc).local();
  return localDate;
};

export const localeDateConvertUtc = (
  date: dayjs.Dayjs | null | string,
  schema: string = 'YYYY-MM-DD',
) => {
  if (date && typeof date !== 'string' && dayjs(date).isValid()) {
    return dayjs(date.format(schema)).utc().format();
  }
  return date;
};

// when the target is nest schema, you should use pathName[] in array.
// i.e: { parent: { target: Date } } keys -> [['parent', 'target']]
export function decorateDateValueToUTC<T extends object>(
  data: T,
  keys: NamePath[],
  schema?: string,
) {
  return keys.reduce((obj: T, key: string | InternalNamePath) => {
    if (typeof key === 'string' && key in obj) {
      return { ...obj, [key]: localeDateConvertUtc(obj[key], schema) };
    }
    if (Array.isArray(key)) {
      const { ...copyObj } = obj;
      updateDeepProperty(copyObj, key, localeDateConvertUtc);
      return copyObj;
    }
    return obj;
  }, data);
}

// it will directly update obj value.
function updateDeepProperty<T>(
  obj: T,
  keys: InternalNamePath,
  callback: (date: dayjs.Dayjs, schema?: string) => string | dayjs.Dayjs | null,
) {
  let current = obj;
  // current will be reassign as inside object.
  // i.e: { a : { b : c: true } } -> { b: { c: value } }
  for (let depth = 0; depth < keys.length - 1; depth++) {
    if (current[keys[depth]]) {
      current = current[keys[depth]];
    }
  }
  const selectLastKey = keys[keys.length - 1];
  // it will find our target key and reassign value.
  // i.e: { c: value } ->  { c: Callback(value) }
  if (current[selectLastKey]) {
    current[selectLastKey] = callback(current[selectLastKey]);
  }
}

export function convertTodayToUTC(inputDate: dayjs.Dayjs) {
  const endDateIsCurrentDate = inputDate && inputDate.isSame(new Date(), 'day');
  const overwriteEndDate = endDateIsCurrentDate && inputDate.utc().format();
  return overwriteEndDate || null;
}

export const disabledDate = (selectDate: Dayjs, dates: RangeValue<Dayjs>) => {
  const tooLate = dates?.[0] && Math.abs(selectDate.diff(dates[0], 'days')) > 89;
  const tooEarly = dates?.[1] && Math.abs(dates[1].diff(selectDate, 'days')) > 89;
  const beforeDays = selectDate > dayjs().subtract(1, 'day').endOf('day');
  return !!beforeDays || !!tooEarly || !!tooLate;
};

export const getInitialDateRange = (): RangeValue<dayjs.Dayjs> => {
  return [dayjs().subtract(30, 'days'), dayjs().subtract(1, 'days')];
};
