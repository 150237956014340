import { getRequestOptionDemoMode } from '@/utils/demoMode';
import { request } from 'umi';

export async function getCampaignListApi(
  params: API.CampaignListGetParameters,
  options?: API.RequestOptions,
) {
  return request<API.CampaignList>('/campaign/list', { params, ...options });
}

export async function getCampaignDetailApi(
  params: API.CampaignDetailGetParameters,
  options?: API.RequestOptions,
) {
  return request<API.CampaignDetail>('/campaign/detail', {
    params,
    ...options,
    ...getRequestOptionDemoMode('name'),
  });
}

export async function getCampaignDetailChartApi(
  params: API.CampaignDetailMetricsChartGetParameters,
  options?: API.RequestOptions,
) {
  return request<API.CampaignDetailMetricsChart>('/campaign/detail/chart', {
    params,
    ...options,
    ...getRequestOptionDemoMode('name'),
  });
}

export const getCampaignSpendingAPI = async (
  params: API.CampaignSpendingGetParameters,
  options?: API.RequestOptions,
) => {
  return request<API.CampaignSpending>('/campaign/spending', {
    params,
    ...options,
    ...getRequestOptionDemoMode(),
  });
};

export const getCampaignPurchasesAPI = async (
  params: API.CampaignPurchasesGetParameters,
  options?: API.RequestOptions,
) => {
  return request<API.CampaignPurchases>('/campaign/purchases', {
    params,
    ...options,
    ...getRequestOptionDemoMode(),
  });
};

export const getCampaignNewUsersAPI = async (
  params: API.CampaignNewUsersGetParameters,
  options?: API.RequestOptions,
) => {
  return request<API.CampaignNewUsers>('/campaign/new_users', {
    params,
    ...options,
    ...getRequestOptionDemoMode(),
  });
};

export const getCampaignAllAPI = async (
  params: API.CampaignAllGetParameters,
  options?: API.RequestOptions,
) => {
  const { media, ...restParams } = params;
  const mediaQueryStr = media.map((mediaStr) => `media=${mediaStr}`).join('&');
  return request<API.CampaignAll>(`/campaign/all?${mediaQueryStr}`, {
    params: restParams,
    ...options,
    ...getRequestOptionDemoMode(),
  });
};

export const getCampaignMonthlyAPI = async (
  params: API.CampaignMonthlyGetParameters,
  options?: API.RequestOptions,
) => {
  const { media, ...restParams } = params;
  const mediaQueryStr = media.map((mediaStr) => `media=${mediaStr}`).join('&');
  return request<API.CampaignAll>(`/campaign/monthly?${mediaQueryStr}`, {
    params: restParams,
    ...options,
    ...getRequestOptionDemoMode(),
  });
};

export const getCampaignStatusAPI = async (options?: API.RequestOptions) => {
  return request<API.CampaignStatus>('/campaign/status', { ...options });
};
