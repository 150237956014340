/* eslint-disable @typescript-eslint/no-unused-vars */
import dayjs from 'dayjs';

import type { RangeValue } from 'rc-picker/es/interface';

interface Query {
  dateRange: RangeValue<dayjs.Dayjs>;
}

type Resp<T> = T & {
  thisTermStart: string | null;
  thisTermEnd: string | null;
};

export const getDateRangeFormat = <T extends Query>(query: T): Resp<T> => {
  const thisTermStart = query.dateRange?.[0]
    ? dayjs(query.dateRange?.[0]).format('YYYY-MM-DD')
    : null;
  const thisTermEnd = query.dateRange?.[1]
    ? dayjs(query.dateRange?.[1]).format('YYYY-MM-DD')
    : null;
  return { ...query, thisTermStart, thisTermEnd };
};

export const formatDurationFromSeconds = (value: number) =>
  dayjs.duration(value as number, 'seconds').format('HH:mm:ss');
