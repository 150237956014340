import { getRequestOptionDemoMode } from '@/utils/demoMode';
import { request } from 'umi';

export async function downloadPerformance(params: API.PerformanceDownloadParametersType) {
  return request<API.DownloadType>('/performance/download', {
    responseType: 'blob',
    params,
    skipErrorMessage: true,
  });
}

export async function getOverviewAPI(
  params: API.OverviewGetParameters,
  options?: API.RequestOptions,
) {
  return request<API.Overview>('/performance/overview', {
    params,
    ...options,
    ...getRequestOptionDemoMode(),
  });
}

export async function getPageViewAPI(
  params: API.PageViewGetParameters,
  options?: API.RequestOptions,
) {
  return request<API.PageView>('/performance/page_view', {
    params,
    ...options,
    ...getRequestOptionDemoMode(),
  });
}

export async function getPageViewAllAPI(
  params: API.PageViewAllGetParameters,
  options?: API.RequestOptions,
) {
  return request<API.PageViewAll>('/performance/page_view/all', { params, ...options });
}

export async function getSessionAPI(params: API.SessionGetParameter, options?: API.RequestOptions) {
  return request<API.Session>('/performance/session', {
    params,
    ...options,
    ...getRequestOptionDemoMode(),
  });
}

export async function getSessionAllAPI(
  params: API.SessionAllGetParameters,
  options?: API.RequestOptions,
) {
  return request<API.SessionAll>('/performance/session/all', { params, ...options });
}

export async function getEngagementRateAPI(
  params: API.EngagementRateGetParameters,
  options?: API.RequestOptions,
) {
  return request<API.EngagementRate>('/performance/engagement_rate', {
    params,
    ...options,
    ...getRequestOptionDemoMode(),
  });
}

export async function getEngagementRateAllAPI(
  params: API.EngagementRateAllGetParameters,
  options?: API.RequestOptions,
) {
  return request<API.EngagementRateAll>('/performance/engagement_rate/all', {
    params,
    ...options,
  });
}

export async function getChannelGroupApi(
  params: API.ChannelGroupGetParameters,
  options?: API.RequestOptions,
) {
  return request<API.ChannelGroup>('/performance/channel_group', {
    params,
    ...options,
    ...getRequestOptionDemoMode(),
  });
}

export const getSourceMediumApi = async (
  params: API.SourceMediumGetParameters,
  options?: API.RequestOptions,
) => {
  return request<API.SourceMedium>('/performance/source_medium', {
    params,
    ...options,
    ...getRequestOptionDemoMode(),
  });
};

export const getAudienceApi = async (
  params: API.AudienceGetParameters,
  options?: API.RequestOptions,
) => {
  return request<API.Audience>('/performance/audience', {
    params,
    ...options,
    ...getRequestOptionDemoMode('audienceName'),
  });
};
