import { getRequestOptionDemoMode } from '@/utils/demoMode';
import { request } from 'umi';

export const getConversionListAPI = async (
  params: API.ConversionListGetParameters,
  options?: API.RequestOptions,
) => {
  return request<API.ConversionList>('/conversion/list', {
    params,
    ...options,
    ...getRequestOptionDemoMode(),
  });
};

export const getConversionDailyAPI = async (
  params: API.ConversionDailyGetParameters,
  options?: API.RequestOptions,
) => {
  return request<API.ConversionDaily>('/conversion/daily', {
    params,
    ...options,
    ...getRequestOptionDemoMode('audienceName'),
  });
};

export const getConversionUserJourneyAPI = async (
  params: API.ConversionUserJourneyGetParameters,
  options?: API.RequestOptions,
) => {
  return request<API.ConversionUserJourney>('/conversion/user_journey', {
    params,
    ...options,
    ...getRequestOptionDemoMode(),
  });
};

export const getConversionDimensionAPI = async (
  params: API.ConversionDimensionGetParameters,
  options?: API.RequestOptions,
) => {
  return request<API.ConversionDimension>('/conversion/dimension', {
    params,
    ...options,
    ...getRequestOptionDemoMode('conversionEvent'),
  });
};

export const getConversionDimensionAllAPI = async (
  params: API.ConversionDimensionAllGetParameters,
  options?: API.RequestOptions,
) => {
  return request<API.ConversionDimensionAll>('/conversion/dimension/all', { params, ...options });
};

export const getConversionSourceMediumAPI = async (
  params: API.ConversionSourceMediumGetParameters,
  options?: API.RequestOptions,
) => {
  return request<API.ConversionSourceMedium>('/conversion/source_medium', {
    params,
    ...options,
    ...getRequestOptionDemoMode(),
  });
};

export const getOverviewAPI = async (
  params: API.ConversionOverviewGetParameters,
  options?: API.RequestOptions,
) => {
  return request<API.RevenueOverview>('/conversion/overview', {
    params,
    ...options,
    ...getRequestOptionDemoMode(),
  });
};
